.wrapper {
  padding: 32px 16px 64px;
  max-width: 500px;
  margin: 32px auto;
  text-align: center;
}

.wrapper p {
  font-size: 0.875rem;
  color: hsl(230deg 75% 88%);
}

.wrapper p a {
  font-weight: 700;
  text-decoration: none;
  color: hsl(230deg 90% 95%);
}
.wrapper p a:hover {
  text-decoration: underline;
}
.wrapper p:not(:last-of-type) {
  margin-bottom: 1em;
}
