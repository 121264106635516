@keyframes toast {
  from {
    transform: translateX(calc(100% + 64px));
  }
}

.wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  list-style-type: none;
}

.toastWrapper {
  animation: toast 800ms cubic-bezier(0, 0.46, 0, 1.04) both;
  will-change: transform;
}
